<template>
  <div class="container">

    <div class="columns is-centered">
      <div class="column">
        <h2 class="title has-text-weight-bold has-text-centered mt-6 mb-3">
          Numéros de téléphone
        </h2>
      </div>
    </div>

         <div class="columns is-multiline is-centered">   
            <div v-for="item in items" class="column is-4 card my-3 mx-3">
              
                <div class="card-content p-2">
                  <div class="media">
                    <div class="media-left">
                      <figure class="logo">
                        <img v-bind:src="`/assets/${item.logo}`">
                      </figure>
                    </div>
                    <div class="media-content p-2">
                      <p class="title is-5 has-text-grey-light">{{ item.titre }}</p>
                      <p class="subtitle is-6 has-text-grey has-text-weight-bold" >{{ item.numero }}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { titre: "Sopress 1 -Sofoot-", numero: "+33 (0)143358271 - 104", logo: "sopress.png" },
        { titre: "Sopress 2 -Sofoot-", numero: "+33 (0)143358252 - 105", logo: "sopress.png" },
        { titre: "Standard -Xerox-", numero: "+33 (0)143228696 - 100", logo: "print-regular.svg"},
        { titre: "H3", numero: "+33 (0)143358265 - 102", logo: "h3.png" },
        { titre: "Compta", numero: "+33 (0)143358261 - 103", logo: "sack-dollar-solid.svg" },
        { titre: "Dooli", numero: "+33 (0)140470109 - 101", logo: "dooli.svg" },
      ],
    };
  },
};
</script>